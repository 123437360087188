<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>价值包参数配置</a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="goBack">配置策略</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>配置规则</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <a-row>
        <a-button type="primary" style="margin-bottom: 12px" @click="onAdd">
          新增
        </a-button>
      </a-row>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="onModify(record.policyId)">修改</a>
            <a-popconfirm
              title="是否确认删除?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="onDelete(record.policyId)"
            >
              <a>删除</a>
            </a-popconfirm>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import {
  deleteStrategyOfPolicy,
  fetchCanChoosePolicy,
  fetchStrategyOfPolicyList
} from "@/services/ValueBag";

const tblColumns = [
  {
    title: "价值包规则类别",
    dataIndex: "policyTypeValue"
  },
  {
    title: "计提周期",
    dataIndex: "extractPeriodValue"
  },
  {
    title: "计提单位",
    dataIndex: "extractUnitValue"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      tblData: [],
      tblColumns,
      canChooseValue: 0,
      showModify: true,
      strategyId: this.$route.params.id,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      startTime: "",
      endTime: ""
    };
  },
  mounted() {
    this.onLoadPolicy();
    this.fetchList();
  },
  methods: {
    //可使用的价值包
    onLoadPolicy() {
      fetchCanChoosePolicy(this.strategyId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.canChooseValue = resp.data.data.length;
        }
      });
    },
    onModify(id) {
      this.$router.push({
        name: "ModifyStrategyRule",
        params: {
          id: this.strategyId,
          policyId: id
        }
      });
    },
    // 新建价值包
    onAdd() {
      if (this.canChooseValue <= 1) {
        this.$message.warning("已经没有可选择的价值包");
      } else {
        this.$router.push({
          name: "AddStrategyRule",
          params: {
            id: this.strategyId
          }
        });
      }
    },
    onDelete(id) {
      deleteStrategyOfPolicy(id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.fetchList();
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 获取列表信息
    fetchList() {
      const params = {
        strategyId: this.strategyId,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      fetchStrategyOfPolicyList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const records = resp.data.data.records;
          const data = resp.data.data;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
          this.tblData = records;
          if (this.tblData.length > 0) {
            this.tblData.forEach(item => {
              item.extractPeriodValue = item.extractPeriodValue
                ? item.extractPeriodValue
                : "--";
              item.extractUnitValue = item.extractUnitValue
                ? item.extractUnitValue
                : "--";
            });
          }
        }
      });
    },
    // 调整时间
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0];
        this.endTime = dateString[1];
      }
    },
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
  margin-right: 40px;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

.row {
  display: flex;
}

.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.btn-reset {
  margin-bottom: 8px;
}

.dealer-type {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}
</style>
